import React, { useContext } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { ProfileContext } from '../context/ProfileContext'

const ResetButton = ({ resetState }) => {
  const intl = useIntl()
  const { resetProfile } = useContext(ProfileContext)

  return (
    <button
      className="py-2 px-4 bg-yellow-button border-yellow-border border-2 text-black font-bold rounded-lg shadow-md hover:bg-yellow-600 focus:outline-none"
      onClick={() => {
        resetProfile(intl.locale)
        resetState()
      }}
    >
      {intl.formatMessage({ id: 'reset' })}
    </button>
  )
}

export default ResetButton

import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import FilterOption from '../components/FilterOptions'
import HowToUse from '../components/HowToUse'
import SortByListBox from '../components/SortByListBox'
import HeroList from '../components/HeroList'
import ResetButton from '../components/ResetButton'
import ShareButton from '../components/ShareButton'

const IndexPage = ({ data }) => {
  const initialState = {
    elementIds: [],
    classIds: [],
    sortBy: 'element',
  }
  // state management
  const [selectedOptions, setSelectedOptions] = useState(initialState)

  const toggleFilterOption = (id, type) => {
    if (type === 'ELEMENT') {
      setSelectedOptions((prevState) => ({
        ...prevState,
        elementIds: toggleValueInArray(prevState.elementIds, id),
      }))
    } else if (type === 'CLASSES')
      setSelectedOptions((prevState) => ({
        ...prevState,
        classIds: toggleValueInArray(prevState.classIds, id),
      }))
  }

  // only sort state profile as order of local storage is not important
  const setSortBy = (selected) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      sortBy: selected,
    }))
  }

  const resetState = () => {
    setSelectedOptions(initialState)
  }

  return (
    <Layout>
      <SEO />
      <div className="flex flex-row flex-wrap w-full lg:max-w-screen-lg mx-auto px-4 pt-2 sm:pt-6">
        {/* <div className="flex flex-row flex-wrap"> */}
        <div className="flex-auto lg:flex-1 flex flex-col mx-auto">
          <h3 className="text-white flex-1">
            <FormattedMessage id="filter_element_class" />
          </h3>
          <div className="flex-1 flex flex-row">
            {data.elements.edges?.map(({ node }) => (
              <FilterOption
                isEnabled={selectedOptions.elementIds.includes(node.contentful_id)}
                onClick={() => toggleFilterOption(node.contentful_id, 'ELEMENT')}
                node={node}
                key={node.contentful_id}
              />
            ))}
          </div>
          <div className="flex-1 flex flex-row flex-wrap items-center">
            {data.classes.edges?.map(({ node }) => (
              <FilterOption
                isEnabled={selectedOptions.classIds.includes(node.contentful_id)}
                onClick={() => toggleFilterOption(node.contentful_id, 'CLASSES')}
                node={node}
                key={node.contentful_id}
              />
            ))}
            <div className="ml-auto inline-flex items-center py-2 sm:py-4">
              <HowToUse />
              <SortByListBox selectedOption={selectedOptions.sortBy} setSelected={setSortBy} />
            </div>
          </div>
          <HeroList selectedOptions={selectedOptions} />
          <div className="flex w-full md:w-1/2 justify-evenly py-6 mx-auto">
            <ResetButton resetState={resetState} />
            <ShareButton />
          </div>
        </div>
        {/* <div className="flex-1 flex-grow-0">
          <h3 className="w-48">Something</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: `
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5327915580283031"
              crossorigin="anonymous">
              </script>
                <ins class="adsbygoogle"
                  style="display:block"
                  data-ad-client="ca-pub-5327915580283031"
                  data-ad-slot="5200730606"
                  data-ad-format="auto"
                  data-full-width-responsive="true">
              </ins>
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
          `,
            }}
          />
        </div> */}
      </div>
    </Layout>
  )
}

const toggleValueInArray = (array, value) => {
  let result = [...array]
  var index = result.indexOf(value)
  if (index === -1) {
    result.push(value)
  } else {
    do {
      result.splice(index, 1)
      index = result.indexOf(value)
    } while (index !== -1)
  }
  return result
}

export default injectIntl(IndexPage)

export const query = graphql`
  query IndexQuery($language: String) {
    classes: allContentfulClasses(filter: { node_locale: { eq: $language } }, sort: { fields: order }) {
      edges {
        node {
          contentful_id
          name
          icon {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
    elements: allContentfulElements(filter: { node_locale: { eq: $language } }, sort: { fields: order }) {
      edges {
        node {
          contentful_id
          name
          icon {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import styles from '../styles/index.style'
import background from '../images/glow.png'

const FilterOption = ({ node, onClick, isEnabled }) => {
  // const data = useStaticQuery(graphql`
  //   query background {
  //     file(absolutePath: { regex: "/images/glow.png/" }) {
  //       publicURL
  //     }
  //   }
  // `)
  return (
    <StyledFilterOption
      // background={data.file.publicURL}
      isEnabled={isEnabled}
      className={styles.filterOption}
      onClick={onClick}
    >
      <GatsbyImage alt={node.name} className="w-8 sm:w-12" image={node.icon.gatsbyImageData} placeholder="blurred" />
      {/* <img className="max-h-10 sm:max-h-full" src={node.icon.fixed.src} alt={node.name} /> */}
    </StyledFilterOption>
  )
}
// background-color: ${(props) => (props.isEnabled ? '#fff4db' : 'transparent')};
const StyledFilterOption = styled.div`
  background: ${(props) => (props.isEnabled ? 'url(' + background + ') center no-repeat' : 'none')};
  background-size: cover;
`

export default FilterOption

import React, { useContext, useEffect } from 'react'
import { navigate, useIntl } from 'gatsby-plugin-intl'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import styles from '../styles/index.style'
import { ProfileContext } from '../context/ProfileContext'
import { pick } from 'lodash'
import buttonCloseImage from '../images/close.png'

export default function ShareButton() {
  const intl = useIntl()
  const { profile, resetProfile } = useContext(ProfileContext)
  let [isProfileEligible, setProfileEligible] = useState(false)
  let [isModalOpen, setModalOpen] = useState(false)
  let [profileName, setProfileName] = useState('')
  let [profileMessage, setProfileMessage] = useState('')
  let [isProfileSubmitting, setProfileSubmitting] = useState(false)

  useEffect(() => {
    setProfileEligible(profile.hasModified && profile.totalGuardianPoints > 0)
  }, [profile.hasModified, profile.totalGuardianPoints])

  const submitProfile = () => {
    setProfileSubmitting(true)
    return (async () => {
      fetch(`/.netlify/functions/submitProfile`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          profile_name: profileName,
          profile_message: profileMessage,
          heroes: profile.myHeroes
            .filter((e) => e.guardianPoints > 0)
            .map((e) => pick(e, ['id', 'evolution', 'guardianPoints', 'heroLimitBreak', 'weaponLimitBreak'])),
        }),
      }).then((res) => {
        if (res.ok)
          res.json().then(({ uuid }) => {
            const translator = require('short-uuid')()
            navigate('/profile/' + translator.fromUUID(uuid))
            resetProfile(intl.locale)
          })
        else {
          setProfileSubmitting(false)
          closeModal()
        }
      })
      // .then((data) => {
      //   if (data.length) setProfileHeroes(data)
      //   else {
      //     // if (error) console.log('error', error)
      //     navigate('/')
      //   }
      // })
      // let { data: locale } = await axios.get('https://geolocation-db.com/json/')
      // let { data: result, error } = await supabase
      //   .from('Profiles')
      //   .insert({
      //     profile_name: profileName,
      //     profile_message: profileMessage,
      //     heroes: profile.myHeroes
      //       .filter((e) => e.guardianPoints > 0)
      //       .map((e) => pick(e, ['id', 'evolution', 'guardianPoints', 'heroLimitBreak', 'weaponLimitBreak'])),
      //     ip_address: locale.IPv4,
      //     country_code: locale.country_code,
      //   })
      //   .single()
      // // console.log(error ?? result)
      // if (result) {
      //   const translator = require('short-uuid')()
      //   navigate('/profile/' + translator.fromUUID(result.uuid))
      //   resetProfile(intl.locale)
      // } else {
      // setProfileSubmitting(false)
      // closeModal()
      // }
    })()
  }

  const closeModal = () => {
    if (!isProfileSubmitting) {
      setModalOpen(false)
    }
  }

  const openModal = () => {
    if (isProfileEligible) setModalOpen(true)
  }

  return (
    <>
      <button type="button" onClick={openModal} className={styles.shareButton(isProfileEligible)}>
        {intl.formatMessage({ id: 'share' })}
      </button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" static onClose={() => closeModal()}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-90 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-700 shadow-xl rounded-2xl">
                <div className="w-full flex items-center">
                  <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-white">
                    {intl.formatMessage({ id: 'input_name_for_profile' })}
                  </Dialog.Title>
                  <div className="ml-auto cursor-pointer" onClick={() => closeModal()}>
                    <button
                      className="w-8 h-8"
                      style={{ background: `url(${buttonCloseImage}) no-repeat center/cover` }}
                    />
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-8 w-8"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg> */}
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    autoFocus
                    onChange={(e) => setProfileName(e.target.value)}
                    type="text"
                    className="mt-1 p-2 block w-full rounded-lg border-gray-300 shadow-sm"
                    placeholder=""
                  />
                </div>
                <h3 className="py-2 text-xl font-medium leading-6 text-white">
                  {intl.formatMessage({ id: 'input_message_for_profile' })}
                </h3>
                <div className="mt-2">
                  <textarea
                    rows={3}
                    onChange={(e) => setProfileMessage(e.target.value)}
                    type="text"
                    className="mt-1 p-2 block w-full rounded-lg border-gray-300 shadow-sm"
                    placeholder=""
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className={styles.submitButton(isProfileSubmitting)}
                    onClick={() => submitProfile()}
                  >
                    <svg className={styles.submitButtonSpinning(isProfileSubmitting)} viewBox="0 0 24 24">
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    {intl.formatMessage({ id: 'submit' })}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

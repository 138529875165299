import React, { Fragment, useContext, useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { Dialog, Transition } from '@headlessui/react'
import styles from '../styles/index.style'
import buttonHowToUseImage from '../images/how_to_use.png'
import buttonCloseImage from '../images/close.png'

export default function HowToUse() {
  const intl = useIntl()
  let [isModalOpen, setModalOpen] = useState(false)

  const closeModal = () => {
    setModalOpen(false)
  }

  const openModal = () => {
    setModalOpen(true)
  }
  const ImageButton = () => {
    let style = {
      background: `url(${buttonHowToUseImage}) no-repeat center/cover`,
    }
    return <button className="w-8 h-8 mx-2" style={style} onClick={() => openModal()} />
  }

  return (
    <>
      <ImageButton />
      {/* <button type="button" onClick={openModal} className={styles.shareButton(isProfileEligible)}>
        {intl.formatMessage({ id: 'share' })}
      </button> */}

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" static onClose={() => closeModal()}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-90 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-700 shadow-xl rounded-2xl">
                <div className="w-full flex items-center">
                  <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-white">
                    {intl.formatMessage({ id: 'how_to_use' })}
                  </Dialog.Title>
                  <div className="ml-auto cursor-pointer" onClick={() => closeModal()}>
                    <button
                      className="w-8 h-8"
                      style={{ background: `url(${buttonCloseImage}) no-repeat center/cover` }}
                    />
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-8 w-8"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg> */}
                  </div>
                </div>
                <div className="relative w-full h-64 mt-2">
                  <div className="absolute top-0 left-0 text-white">
                    <div>{intl.formatMessage({ id: 'hero_evolution' })}</div>
                    <div>{intl.formatMessage({ id: 'hero_evolution_range' })}</div>
                    <span className="text-sm text-yellow-300">
                      {intl.formatMessage({ id: 'hero_evolution_reset' })}
                    </span>
                  </div>
                  <div className="absolute top-0 right-0 text-white text-right w-36 break-words">
                    <div>{intl.formatMessage({ id: 'own_exclusive_weapon' })}</div>
                    <span className="text-sm text-yellow-300">
                      {intl.formatMessage({ id: 'weapon_limit_break_reset' })}
                    </span>
                  </div>
                  <div className="absolute bottom-0 left-0 text-white">
                    <div>{intl.formatMessage({ id: 'hero_limit_break' })}</div>
                    <div>{intl.formatMessage({ id: 'limit_break_range' })}</div>
                  </div>
                  <div className="absolute bottom-0 right-0 text-white text-right">
                    <div>{intl.formatMessage({ id: 'weapon_limit_break' })}</div>
                    <div>{intl.formatMessage({ id: 'limit_break_range' })}</div>
                  </div>
                  <div className="w-60 h-60 mx-auto py-1">
                    <StaticImage
                      className=""
                      src="../images/hint.png"
                      alt={intl.formatMessage({ id: 'how_to_use' })}
                      placeholder="blurred"
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

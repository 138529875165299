import React from 'react'
import useProfile from '../context/useProfile'
import HeroSelection from '../components/HeroSelection'
import styles from '../styles/index.style'

const HeroList = ({ selectedOptions }) => {
  const [profile] = useProfile()

  return (
    <div className={styles.heroSelectionGrid}>
      {profile.myHeroes &&
        profile.myHeroes.map((hero) =>
          // case 1: show all when nothing is selected
          (selectedOptions.elementIds.length === 0 && selectedOptions.classIds.length === 0) ||
          // case 2: show element heroes when no class is selected
          (selectedOptions.elementIds.includes(hero.element.id) && selectedOptions.classIds.length === 0) ||
          // case 3: show class heroes when no element is selected
          (selectedOptions.elementIds.length === 0 && selectedOptions.classIds.includes(hero.class.id)) ||
          // case 4: show heroes regarding selected elements and classes
          (selectedOptions.elementIds.includes(hero.element.id) && selectedOptions.classIds.includes(hero.class.id)) ? (
            <HeroSelection node={hero} key={hero.id} />
          ) : null
        )}
    </div>
  )
}

export default HeroList

import React, { Fragment, useContext, useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { ProfileContext } from '../context/ProfileContext'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { orderBy } from 'lodash'

const SortByListBox = ({ selectedOption, setSelected }) => {
  const intl = useIntl()
  const { setProfile } = useContext(ProfileContext)

  // only sort state profile as order of local storage is not important
  const sortHeroList = (sortBy) => {
    if (sortBy === 'completeness') {
      setProfile((prevState) => ({
        ...prevState,
        myHeroes: orderBy(
          prevState.myHeroes,
          ['guardianPoints', 'releaseDate', 'element.order', 'asc'],
          ['desc', 'desc']
        ),
      }))
    } else if (sortBy === 'hero_limit_break') {
      setProfile((prevState) => ({
        ...prevState,
        myHeroes: orderBy(
          prevState.myHeroes,
          ['evolution', 'heroLimitBreak', 'guardianPoints', 'releaseDate', 'element.order'],
          ['desc', 'desc', 'desc', 'desc', 'asc']
        ),
      }))
    } else if (sortBy === 'weapon_limit_break') {
      setProfile((prevState) => ({
        ...prevState,
        myHeroes: orderBy(
          prevState.myHeroes,
          ['weaponLimitBreak', 'guardianPoints', 'releaseDate', 'element.order'],
          ['desc', 'desc', 'desc', 'asc']
        ),
      }))
    } else if (sortBy === 'element') {
      setProfile((prevState) => ({
        ...prevState,
        myHeroes: orderBy(prevState.myHeroes, ['element.order', 'releaseDate'], ['asc', 'desc']),
      }))
    } else if (sortBy === 'class') {
      setProfile((prevState) => ({
        ...prevState,
        myHeroes: orderBy(prevState.myHeroes, ['class.order', 'releaseDate', 'element.order'], ['asc', 'desc', 'asc']),
      }))
    } else if (sortBy === 'release_date') {
      setProfile((prevState) => ({
        ...prevState,
        myHeroes: orderBy(prevState.myHeroes, ['releaseDate', 'element.order'], ['desc', 'asc']),
      }))
    }
  }

  const options = [
    { name: intl.formatMessage({ id: 'completeness' }), value: 'completeness' },
    { name: intl.formatMessage({ id: 'hero_limit_break' }), value: 'hero_limit_break' },
    { name: intl.formatMessage({ id: 'weapon_limit_break' }), value: 'weapon_limit_break' },
    { name: intl.formatMessage({ id: 'release_date' }), value: 'release_date' },
    { name: intl.formatMessage({ id: 'element' }), value: 'element' },
    { name: intl.formatMessage({ id: 'class' }), value: 'class' },
  ]

  return (
    <Listbox
      value={selectedOption}
      onChange={(option) => {
        sortHeroList(option)
        setSelected(option)
      }}
    >
      <div className="relative">
        <Listbox.Button className="relative w-60 sm:w-72 py-2 pl-3 pr-10 text-left bg-white rounded-md shadow-md cursor-default sm:text-sm">
          <span className="block truncate">
            {intl.formatMessage(
              { id: 'sort_by' },
              {
                option: options.find((e) => e.value === selectedOption).name,
              }
            )}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute py-1 w-60 sm:w-72 mt-1 overflow-hidden text-base bg-white rounded-md shadow-lg max-h-60 sm:text-sm z-10">
            {options.map((option, i) => (
              <Listbox.Option
                key={i}
                className={({ active }) =>
                  `${
                    active ? 'text-yellow-900 bg-yellow-100' : 'text-gray-900'
                  } cursor-default select-none relative py-2 pl-10 pr-4 -ml-2`
                }
                value={option.value}
              >
                {({ selected, active }) => (
                  <>
                    <span className={`${selected ? 'font-bold' : 'font-normal'} block truncate`}>{option.name}</span>
                    {selected ? (
                      <span
                        className={`${active ? 'text-yellow-600' : 'text-yellow-600'}
                                absolute inset-y-0 left-0 flex items-center pl-4`}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default SortByListBox

import React, { useState, useRef } from 'react'
import { useClickAway } from 'react-use'
import { useIntl } from 'gatsby-plugin-intl'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
// import { Textfit } from 'react-textfit'
// import EvolutionStar from './EvolutionStar'
import styles from '../styles/index.style'
import styled from 'styled-components'
import useProfile from '../context/useProfile'

const HeroSelection = ({ node }) => {
  const intl = useIntl()
  const [profile, modifyHeroStat, getMaxEvoStage] = useProfile()
  const [onTouchStart, setOnTouchStart] = useState(false)
  const [onTouchEnd, setOnTouchEnd] = useState(false)
  const clickRef = useRef()
  useClickAway(clickRef, () => {
    setOnTouchStart(false)
    setOnTouchEnd(false)
  })

  let hero = profile?.myHeroes?.find((e) => e.id === node.id)
  let hasHero = hero?.evolution > -1
  let hasHeroMaxEvolved = hero?.evolution === getMaxEvoStage(hero)
  let hasWeapon = hero?.weaponLimitBreak > -1

  return (
    <StyledHeroSelection
      className={styles.heroSelection(hasHero)}
      onTouchStart={() => setOnTouchStart(true)}
      onTouchMove={() => setOnTouchStart(false)}
      onTouchEnd={() => {
        setOnTouchEnd(onTouchStart)
        // setOnTouchEnd(
        //   // case 1: enable with single touch, or
        //   (onTouchStart && !onTouchEnd) ||
        //     // case 2: disable when already enabled with single touch
        //     (onTouchEnd && !onTouchStart)
        // )
      }}
      ref={clickRef}
    >
      <div className="absolute">
        <GatsbyImage
          alt={node.name}
          className={styles.heroImage(hasHero)}
          image={node.gatsbyImageData}
          placeholder="blurred"
          imgStyle={{ borderRadius: '0.75rem' }}
        />
      </div>
      {/* <img className={styles.heroImage(hasHero)} src={node.img} /> */}
      <div className={styles.hoverLayer(onTouchEnd)}>
        <div
          className={styles.heroLimitBreakButton}
          onClick={() => {
            modifyHeroStat(node, 'hero')
          }}
        >
          {hasHero ? (
            <div className={styles.limitBreakButtonText}>{'+' + hero.heroLimitBreak}</div>
          ) : (
            <StaticImage
              className={styles.limitBreakButtonImg}
              src="../images/head_0.png"
              alt={intl.formatMessage({ id: 'hero_limit_break' })}
              placeholder="blurred"
            />
          )}
        </div>
        <div
          className={styles.weaponLimitBreakButton}
          onClick={() => {
            modifyHeroStat(node, 'weapon')
          }}
        >
          {hasWeapon ? (
            <div className={styles.limitBreakButtonText}>{'+' + hero.weaponLimitBreak}</div>
          ) : (
            <StaticImage
              className={styles.limitBreakButtonImg}
              src="../images/weapon_0.png"
              alt={intl.formatMessage({ id: 'weapon_limit_break' })}
              placeholder="blurred"
            />
          )}
        </div>
      </div>
      {hasHero || hasWeapon ? (
        <div
          className={styles.displayLayer(onTouchEnd)}
          style={{
            background: 'linear-gradient(0deg, black, transparent 25%, transparent 90%, rgba(0,0,0,.5))',
          }}
        >
          {hasHero && hasHeroMaxEvolved ? (
            <div className={styles.limitBreakText(true)} style={{ textShadow: '0.1em 0.1em 0.1em black' }}>
              <div className="w-2/5 text-center">
                {/* <Textfit mode="single" forceSingleModeWidth={false} min={1}> */}
                {87 + hero.heroLimitBreak}
                {/* </Textfit> */}
              </div>
              <StaticImage
                className="w-2/5"
                src="../images/head_1.png"
                alt={intl.formatMessage({ id: 'hero_limit_break' })}
                placeholder="blurred"
              />
            </div>
          ) : null}
          {hasWeapon ? (
            <div className={styles.limitBreakText(false)} style={{ textShadow: '0.1em 0.1em 0.1em black' }}>
              <div className="w-2/5 text-center">
                {/* <Textfit mode="single" forceSingleModeWidth={false} min={1}> */}
                {87 + hero.weaponLimitBreak}
                {/* </Textfit> */}
              </div>
              <StaticImage
                className="w-2/5"
                src="../images/weapon_1.png"
                alt={intl.formatMessage({ id: 'weapon_limit_break' })}
                placeholder="blurred"
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {hasHero ? (
        <div
          className="relative cursor-pointer select-none"
          onClick={() => {
            modifyHeroStat(node, 'evolution')
          }}
        >
          <div className={styles.heroEvolutionText} style={{ textShadow: '0.1em 0.1em 0.1em black' }}>
            {hero.evolution + (5 - getMaxEvoStage(hero))}
          </div>
          {/* <EvolutionStar className="absolute top-1 left-3 xl:-ml-1 xl:-mt-0.5" /> */}
          <div className="absolute top-0 left-3 xl:-ml-1 mt-0.5 md:mt-1 lg:mt-0.5 w-4 h-4">
            <StaticImage
              src="../images/star.png"
              alt={intl.formatMessage({ id: 'hero_evolution' })}
              placeholder="blurred"
            />
          </div>
        </div>
      ) : null}
      {hasWeapon ? (
        <div
          className="cursor-pointer"
          style={{ height: 0 }}
          onClick={() => {
            modifyHeroStat(node, 'weapon', true)
          }}
        >
          <StaticImage
            className={styles.weaponFlag}
            src="../images/flag.png"
            alt={intl.formatMessage({ id: 'own_exclusive_weapon' })}
            style={{ position: 'absolute', marginTop: '-6%', marginRight: '-2%' }}
            placeholder="blurred"
          />
        </div>
      ) : null}
    </StyledHeroSelection>
  )
}

const StyledHeroSelection = styled.div`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .hero_selection_layer {
        display: block;
      }
      .hero_display_layer {
        display: none;
      }
    }
  }
`

export default HeroSelection
